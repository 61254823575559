import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a977270"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "el-form-item__label"
}
const _hoisted_2 = { class: "el-form-after-box" }
const _hoisted_3 = { class: "el-form-item__content" }
const _hoisted_4 = {
  key: 0,
  class: "el-form-item__error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ui-form-item-box el-form-item", { 'is-error': !!_ctx._error, 'is-required': _ctx.required }])
  }, [
    (_ctx.showLabel)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", { error: _ctx._error }, undefined, true)
      ]),
      (_ctx._error && _ctx.showError)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx._error.message), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}