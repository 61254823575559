
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class extends Vue {
  get mesureList() {
    let res = { children: [] };
    let loopParentCheck = (pushItem) => {
      if (!pushItem.parentInfo) return;
      loopParentCheck(pushItem.parentInfo);
    };
    let getDeep = (id, data, parentInfo?) => {
      for (let i = 0; i < this.list.length; i++) {
        let item = this.list[i];
        if (item.parentId == id) {
          let pushItem = { ...item, parentInfo };
          let children = getDeep(item.id, [], pushItem);
          if (children.length > 0) {
            pushItem.children = children;
          }
          if (this.defaultKeys[pushItem.id]) {
            loopParentCheck(pushItem);
          }
          data.push(pushItem);
        }
      }
      return data;
    };
    getDeep(null, res.children);
    return res.children;
  }

  get defaultKeys() {
    let res = {};
    this.formData.roleMiddleList.map((item, index) => {
      res[item.roleItemId] = `${index}`;
    });
    return res;
  }

  list: any[] = []

  loading = false
  submitLoading = false
  formData: any = {
    name: "",
    description: "",
    roleMiddleList: [],
    storeId: true,
  }
  async created() {
    this.loading = true;
    await this.getList();
    await this.getData();
    this.loading = false;
  }

  removeItem(data) {
    let removeChildren = (children) => {
      if (!children) return;
      children.map((item) => {
        if (item.children) {
          removeChildren(item.children);
        }
        if (this.defaultKeys[item.id]) {
          this.formData.roleMiddleList.splice(this.defaultKeys[item.id], 1);
        }
      });
    };
    removeChildren(data.children);
    if (this.defaultKeys[data.id]) {
      this.formData.roleMiddleList.splice(this.defaultKeys[data.id], 1);
    }
  }
  addItem(data) {
    let addParent = (infoItem) => {
      if (!this.defaultKeys[infoItem.id]) {
        this.formData.roleMiddleList.push({
          roleItemId: infoItem.id,
        });
      }
      if (infoItem.parentInfo) {
        addParent(infoItem.parentInfo);
      }
    };
    addParent(data);
  }
  changeEvent(data, bool) {
    if (bool) {
      return this.addItem(data);
    }
    this.removeItem(data);
  }
  async getData() {
    if (!this.$route.query.id) return;
    let id = this.$route.query.id;
    let { error, data } = await this.$get("/manager/role/info", {
      id: id,
    });
    if (error) return;
    if (data.isSuper) {
      data.roleMiddleList = this.list.map((item) => {
        return { roleItemId: item.id };
      });
    }
    this.formData = data;
  }
  async getList() {
    let { error, data } = await this.$get("/manager/role/role-item-list");
    if (error) return;
    this.list = data;
  }
  async submitEvent() {
    if (this.formData.id) {
      await this.$postNotify("/manager/role/edit", {
        ...this.formData,
        roleMiddleList: this.formData.roleMiddleList.map(
          (item) => item.roleItemId
        ),
      });
    } else {
      await this.$postNotify("/manager/role/add", {
        ...this.formData,
        roleMiddleList: this.formData.roleMiddleList.map(
          (item) => item.roleItemId
        ),
      });
    }
    this.$router.back();
  }
}
