import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-888e356c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "create-shop" }
const _hoisted_2 = { class: "operat-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_dialog_title = _resolveComponent("ui-dialog-title")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_ui_form_item = _resolveComponent("ui-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_link = _resolveComponent("el-link")!
  const _component_ui_form = _resolveComponent("ui-form")!
  const _component_ui_dialog_container = _resolveComponent("ui-dialog-container")!

  return (_openBlock(), _createBlock(_component_ui_dialog_container, null, {
    header: _withCtx(() => [
      _createVNode(_component_ui_dialog_title, {
        title: "创建店铺",
        onClose: _ctx.close
      }, null, 8, ["onClose"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ui_form, {
          ref: "form",
          "label-width": "140px",
          size: "small"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ui_form_item, {
              label: "店铺名称",
              required: true,
              data: _ctx.forms.name
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.forms.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.forms.name) = $event)),
                  placeholder: "请输入店铺名称"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["data"]),
            _createVNode(_component_ui_form_item, { label: "" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_el_button, {
                    onClick: _ctx.submit,
                    type: "primary"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("确定 ")
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_el_link, {
                    onClick: _ctx.close,
                    underline: false
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("取消")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 512)
      ])
    ]),
    _: 1
  }))
}