
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator'
import { Action, State } from 'vuex-facing-decorator';
import QRCode from "qrcode";

@Component({})
export default class extends Vue {
  @State((state) => state.user.user)
  userInfo;

  @Action("user/getUserInfo")
  getUserInfo;

  get openid() {
    return this.userInfo?.openid
  }

  canbind = false;
  _isDestroyed = false;
  qrcode = ''
  async submit() {
    let { error, message } = await this.$get("/manager/offcial/bind");
    if (error) {
      return this.$error(message);
    }
    this.$success("绑定成功");
    this.$emit('resolve');
  }
  async created() {
    let { error } = await this.getQrcode();
    if (error) return;
    await this.$sleep(1000);
    this.getLoop();
  }
  async getLoop() {
    if (this._isDestroyed) {
      return;
    }
    await this.checkBind();
    if (this._isDestroyed) {
      return;
    }
    await this.$sleep(2000);
    this.getLoop();
  }
  async cancelBind() {
    let { error, message } = await this.$get("/manager/offcial/unbind");
    if (error) {
      return this.$error(message);
    }
    this.$success("取消成功");
    this.getUserInfo()
  }
  async checkBind() {
    let { error, data } = await this.$get("/manager/offcial/can-bind");
    if (error) {
      this.canbind = false;
      return;
    }
    this.canbind = data;
  }
  async getQrcode() {
    let { error, data, message } = await this.$get("/manager/offcial/get-qrcode");
    if (error) {
      this.$error(message);
      return { error, data, message }
    }
    QRCode.toDataURL(data.url, (err, url) => {
      if (err) return;
      this.qrcode = url;
    });
    return { error, data, message }
  }
  close() {
    this.$emit("reject", { msg: '取消' })
  }
  beforeUnmount() {
    this._isDestroyed = true;
  }
}
